import '@rce-web/ui-upgrade-dialog/dist/styles.css';
import '../../style/upgrade-dialog-styles.css';

import hopinApi from '@api/hopin';
import {
  AlertsContext,
  withAlertsProvider,
} from '@features/alerts/alerts-provider';
import { withThemeProvider } from '@features/branding/withThemeProvider';
import {
  useLocalization,
  withLocalizationProvider,
} from '@features/localization';
import { usePublishEventHandler } from '@features/publishing';
import { ReactIDProvider } from '@hopin-team/react-id';
import { UpgradeDialog } from '@rce-web/ui-upgrade-dialog';
import * as Routes from '@routes';
import camelizeKeys from '@util/camelize-keys';
import useIsFreeTrial from '@util/hooks/use-is-free-trial';
import { LOGGER_NAMES } from '@util/logger';
import withErrorHandling from '@util/with-error-handling';
import compose from 'lodash/fp/compose';
import { arrayOf, bool, object, string } from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { setAuthToken } from '@/redux/reducers/auth';
import { setEvent } from '@/redux/reducers/event';
import { setOrganization } from '@/redux/reducers/organization';
import { setRegistration } from '@/redux/reducers/registration';
import { setUser } from '@/redux/reducers/user';
import withReduxProvider from '@/redux/with-provider';

import Nav from './nav-component';
import withConnectedRouter from './with-connected-router';

const NavContainer = ({
  navigationView,
  authToken,
  event,
  eventId,
  newRegistrationsAttendeesEnabled,
  newRegistrationsDashboardEnabled,
  newRegistrationsUrl,
  hopinCanvasEnabled,
  ticketGroupsDeprecated,
  venueType,
  onsiteUpgradeFlowEnabled,
  customDomainsShowPage,
  creationEnableGlobalTags,
  user,
  userImage,
  registration,
  supportUrl,
  isAppAreaVisible,
  surveyBuilderEnabled,
  reorderEventDashboardNavTabs,
  showEngagementScore,
  reactPagesVariation,
}) => {
  const dispatch = useDispatch();
  const { addAlert } = useContext(AlertsContext);
  const [upgradeDialogOpen, setUpgradeDialogOpen] = React.useState(false);
  const { t } = useLocalization();

  useEffect(() => {
    dispatch(setAuthToken(authToken));
    dispatch(setEvent(camelizeKeys(event)));
    dispatch(setOrganization(camelizeKeys(event.organization)));
    dispatch(setUser(camelizeKeys(user)));
    dispatch(setRegistration(camelizeKeys(registration)));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const eventProps = useSelector(
    ({ event, organization }) => ({
      canDelete: event.validForDeletion,
      image: event.picture,
      isDraft: event.draft,
      isAnonymised: event.anonymised,
      isLite: event.isLite,
      name: event.name,
      slug: event.slug,
      startTime: event.timeStartLocal,
      maxPrice: event.maxPrice,
      organization: organization,
      hasTickets: event.hasTickets,
      areas: event.areas,
      eventWizardTemplate: event.eventWizardTemplate,
      venueType,
      eventId,
      primaryBackstageId: event.primaryBackstageId || 0,
      session: event.session,
      started: event.started,
      finished: event.finished,
      phase: event.phase,
      canPublishEvent: Boolean(
        organization.currentPaymentPlan.features.publishEvent,
      ),
    }),
    shallowEqual,
  );

  const { isFreeTrial, isDraft } = useIsFreeTrial();
  const canPublishEvent = !isFreeTrial || (isFreeTrial && !isDraft);

  const lead = {
    firstName: user.first_name,
    lastName: user.last_name,
    email: user.email,
    organisationName: event.organization.name,
  };
  const options = {
    host: '',
    organisationId: event.organization.external_id,
    source: 'event_dashboard',
  };

  const publishing = useSelector(({ event }) => event.isPublishing);
  const userName = useSelector(
    ({ user }) => `${user.firstName} ${user.lastName}`,
  );
  const orgHasMerchantId = useSelector(
    ({ organization }) => !!organization.merchantId,
  );

  const addGenericCatchAlert = () => {
    addAlert({
      active: true,
      text: 'Something went wrong',
      pattern: 'error',
    });
  };

  const handlePublish = usePublishEventHandler({ shouldConfirm: true });

  const publishHandler = () => {
    if (!canPublishEvent) {
      setUpgradeDialogOpen(true);
      return;
    }
    handlePublish();
  };

  const handleEventDuplicate = () => {
    const { slug } = eventProps;

    window.location.assign(Routes.organisersEventDuplicationsPath(slug));
  };

  const handleEventDelete = async () => {
    const confirm = window.confirm(t('overview.delete-event.dialog'));
    if (confirm) {
      try {
        const result = await hopinApi.deleteEvent(eventProps.slug);
        if (result.ok) {
          window.location = Routes.organizationsEventsPath();
        } else if (result.alert) {
          addAlert({
            active: true,
            text: result.alert,
            pattern: 'error',
          });
        }
      } catch (err) {
        console.log(err);
        addGenericCatchAlert();
      }
    }
  };

  return (
    <ReactIDProvider>
      <Nav
        navigationView={navigationView}
        event={{ ...eventProps }}
        isLoading={!eventProps.slug}
        creationEnableGlobalTags={creationEnableGlobalTags}
        onEventDelete={handleEventDelete}
        onPublish={publishHandler}
        onEventDuplicate={handleEventDuplicate}
        orgHasMerchantId={orgHasMerchantId}
        publishing={publishing}
        userImage={userImage}
        userName={userName}
        newRegistrationsAttendeesEnabled={newRegistrationsAttendeesEnabled}
        newRegistrationsDashboardEnabled={newRegistrationsDashboardEnabled}
        newRegistrationsUrl={newRegistrationsUrl}
        supportUrl={supportUrl}
        hopinCanvasEnabled={hopinCanvasEnabled}
        ticketGroupsDeprecated={ticketGroupsDeprecated}
        venueType={venueType}
        onsiteUpgradeFlowEnabled={onsiteUpgradeFlowEnabled}
        customDomainsShowPage={customDomainsShowPage}
        isAppAreaVisible={isAppAreaVisible}
        surveyBuilderEnabled={surveyBuilderEnabled}
        reorderEventDashboardNavTabs={reorderEventDashboardNavTabs}
        canPublishEvent={canPublishEvent}
        showEngagementScore={showEngagementScore}
        reactPagesVariation={reactPagesVariation}
      />
      {!canPublishEvent ? (
        <UpgradeDialog
          isOpen={upgradeDialogOpen}
          lead={lead}
          onClose={() => {
            setUpgradeDialogOpen(false);
          }}
          options={options}
          translations={t('upgrade-dialog')}
        />
      ) : null}
    </ReactIDProvider>
  );
};

NavContainer.propTypes = {
  navigationView: string,
  authToken: string.isRequired,
  creationEnableGlobalTags: bool,
  event: object.isRequired,
  eventId: string.isRequired,
  newRegistrationsAttendeesEnabled: bool,
  newRegistrationsDashboardEnabled: bool,
  hopinCanvasEnabled: bool,
  ticketGroupsDeprecated: bool,
  venueType: string.isRequired,
  onsiteUpgradeFlowEnabled: bool,
  customDomainsShowPage: string,
  supportUrl: string.isRequired,
  user: object.isRequired,
  userImage: string.isRequired,
  newRegistrationsUrl: string.isRequired,
  registration: object,
  isAppAreaVisible: bool,
  surveyBuilderEnabled: bool,
  reorderEventDashboardNavTabs: bool,
  freeTrialEnabled: bool,
  showEngagementScore: bool,
  reactPagesVariation: arrayOf(string),
};

export default compose(
  withErrorHandling({ tag: LOGGER_NAMES.EVENT_DASH_NAV }),
  withReduxProvider,
  withAlertsProvider,
  withConnectedRouter,
  withThemeProvider,
  withLocalizationProvider,
)(NavContainer);
